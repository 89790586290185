exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[playerid]-[matchid]-game-id-highlight-id-index-jsx": () => import("./../../../src/pages/[playerid]/[matchid]/[gameId]/[highlightId]/index.jsx" /* webpackChunkName: "component---src-pages-[playerid]-[matchid]-game-id-highlight-id-index-jsx" */),
  "component---src-pages-[playerid]-[matchid]-index-jsx": () => import("./../../../src/pages/[playerid]/[matchid]/index.jsx" /* webpackChunkName: "component---src-pages-[playerid]-[matchid]-index-jsx" */),
  "component---src-pages-date-date-id-index-jsx": () => import("./../../../src/pages/date/[dateId]/index.jsx" /* webpackChunkName: "component---src-pages-date-date-id-index-jsx" */),
  "component---src-pages-date-date-id-match-id-index-jsx": () => import("./../../../src/pages/date/[dateId]/[matchId]/index.jsx" /* webpackChunkName: "component---src-pages-date-date-id-match-id-index-jsx" */),
  "component---src-pages-date-date-id-match-id-play-id-jsx": () => import("./../../../src/pages/date/[dateId]/[matchId]/[playId].jsx" /* webpackChunkName: "component---src-pages-date-date-id-match-id-play-id-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-recentdownloads-index-js": () => import("./../../../src/pages/recentdownloads/index.js" /* webpackChunkName: "component---src-pages-recentdownloads-index-js" */),
  "component---src-pages-topplaysoftheweek-highlight-id-index-js": () => import("./../../../src/pages/topplaysoftheweek/[highlightId]/index.js" /* webpackChunkName: "component---src-pages-topplaysoftheweek-highlight-id-index-js" */),
  "component---src-pages-topplaysoftheweek-index-js": () => import("./../../../src/pages/topplaysoftheweek/index.js" /* webpackChunkName: "component---src-pages-topplaysoftheweek-index-js" */)
}

